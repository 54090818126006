export default class ValidationService {
    private AlphaNumericUnderscore = /^[a-zA-Z0-9_]+$/;

    private IS_VALID_URL_REGEX = /^(http|https):\/\/[^ "]+$/;

    public isNull = (value: any): value is null => {
        return value === null;
    }

    public isArray = (value: any): value is Array<any> => {
        return Array.isArray(value);
    }

    public isString = (value: any): value is string => {
        return typeof value === 'string';
    }

    public isObject = (value: any): value is object => {
        return value ? typeof value === 'object' && !Array.isArray(value) && !this.isNull(value) : false;
    }

    public isFunction = (value: any): value is Function => {
        return value ? typeof value === 'function' : false;
    }

    public isNumber = (value: any): value is number => {
        return typeof value === 'number';
    }

    public isBoolean = (value: any): value is boolean => {
        return typeof value === 'boolean';
    }

    public isArrayOfStrings = (value: any): value is Array<string> => {
        if (!this.isArray(value)) return false;
        for (let i = 0; i < value.length; i++) {
            if (!this.isString(value[i])) return false;
        }
        return true;
    }

    public isUndefined = (value: any): value is undefined => {
        return value == undefined;
    }

    public objectKeyValuesAreStrings = (value: object): value is { [key: string]: string } => {
        if (!this.isObject(value)) {
            return false;
        }

        const entries = Object.entries(value);

        for (let i = 0; i < entries.length; i++) {
            const [key, value] = entries[i];
            if (!this.isString(key) || !this.isString(value)) {
                return false;
            }
        }

        return true;
    }

    public isAlphaNumericUnderscore = (value: string): boolean => {
        return this.AlphaNumericUnderscore.test(value);
    }

    public isValidUrl = (url: string): boolean => {
        return this.IS_VALID_URL_REGEX.test(url);
    }
}
