import React, {useContext} from 'react';
import LazyLoader from "./../LazyLoader";
import {LoadingContext} from "../../../context/loading/LoadingContext";
import './loader.scss';

const Loader = () => {
    const { isLoading } = useContext(LoadingContext)
    return (
        <>
            {isLoading && ( <LazyLoader /> )}
        </>
    );
};

export default Loader;