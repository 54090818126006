import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import {HttpService} from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/report`;
const endpoints = {
    getTestListReport: () => `${RESOURCE_ENDPOINT}/test-list-report`,
    getReportInvoiceList: () => `${RESOURCE_ENDPOINT}/invoice-report`,
    getMoneyReceiptReport: () => `${RESOURCE_ENDPOINT}/money-receipt-report`,
    getDeliveryChallanReport: () => `${RESOURCE_ENDPOINT}/delivery-challan-report`,
    getIncomeStatementReport: () => `${RESOURCE_ENDPOINT}/income-statement-report`,
    getPaymentSummaryReport: () => `${RESOURCE_ENDPOINT}/payment-summary-report`,
    getReportExpertAllowanceList: () => `${RESOURCE_ENDPOINT}/expert-allowance-report`,
    getAnalysisReportEndPoint: () => `${RESOURCE_ENDPOINT}/analysis-report`,
    getAnalysisTestResultReportEndPoint: () => `${RESOURCE_ENDPOINT}/analysis-report-test-result`,
    getRadiationExposureExternalList: () => `${RESOURCE_ENDPOINT}/radiation-exposure-external-report`,
    getCertificateReport: () => `${RESOURCE_ENDPOINT}/certificate`,
    getRadioactivityTestCertificateReport: () => `${RESOURCE_ENDPOINT}/radioactivity-test-certificate`,
    getRadioactivityTestCertificateResultReport: () => `${RESOURCE_ENDPOINT}/radioactivity-test-certificate-result`,
    getApplicationStatus: () => `${RESOURCE_ENDPOINT}/application-status`,
    getServiceRecipientReport: () => `${RESOURCE_ENDPOINT}/service-recipient-report`,
}

export default class TextConsultancyReportApi {
    public getTestListReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getTestListReport();
        return HttpService.get(url, params, headers);
    }
    public getInvoiceReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getReportInvoiceList();
        return HttpService.get(url, params, headers);
    }
    public getMoneyReceiptReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getMoneyReceiptReport();
        return HttpService.get(url, params, headers);
    }
    public getDeliveryChallanReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getDeliveryChallanReport();
        return HttpService.get(url, params, headers);
    }
    public getIncomeStatementReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getIncomeStatementReport();
        return HttpService.get(url, params, headers);
    }
    public getPaymentSummaryReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPaymentSummaryReport();
        return HttpService.get(url, params, headers);
    }
    public getReportExpertAllowanceList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getReportExpertAllowanceList();
        return HttpService.get(url, params, headers);
    }
    public getAnalysisReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAnalysisReportEndPoint();
        return HttpService.get(url, params, headers);
    }
    public getAnalysisTestResultReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getAnalysisTestResultReportEndPoint();
        return HttpService.get(url, params, headers);
    }
    public getRadiationExposureExternalReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadiationExposureExternalList();
        return HttpService.get(url, params, headers);
    }
    public getCertificateReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCertificateReport();
        return HttpService.get(url, params, headers);
    }
    public getRadioactivityTestCertificateReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadioactivityTestCertificateReport();
        return HttpService.get(url, params, headers);
    }
    public getRadioactivityTestCertificateResultReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadioactivityTestCertificateResultReport();
        return HttpService.get(url, params, headers);
    }
    public getApplicationStatus = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getApplicationStatus();
        return HttpService.get(url, params, headers);
    }

    public getServiceRecipientReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getServiceRecipientReport();
        return HttpService.get(url, params, headers);
    }
}
