export default class PermissionService {
    hasPermission = (permission: any, scopes: string[]) => {
        const hasPermission = scopes?.includes(permission);
        return hasPermission ? true : false;
    }
}

/*
{authorization.hasPermission("users") && (
    <AppNavItemLink to="/users">Users</AppNavItemLink>
)}
*/
