import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CDN_SERVER_PREFIX}/csv`
const endpoints = {
    getCsvData: () => `${RESOURCE_ENDPOINT}`,
}

export default class CsvApi {
    public getCsvData = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCsvData();
        return HttpService.post(url, payload, params, headers);
    }
}