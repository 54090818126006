import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/frontend`;
const endpoints = {
  getAllTestList: () => `${RESOURCE_ENDPOINT}/all-test-list`,
}

export default class FrontEndTestListApi {
  public getAllTestList = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.getAllTestList();
    return HttpService.get(url, params, headers);
  }

}
