import React, {useEffect, createContext, useState} from 'react'
import {io} from "socket.io-client";
import {CONSTANT_CONFIG} from "../../constants";

const initialState = {
    socket: null,
};

export const SocketContext = createContext<any>(initialState);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState<any>(initialState.socket);

    useEffect(() => {
        if (!socket) {
            const ENDPOINT: string = `${CONSTANT_CONFIG.WEB_SOCKET_URL}`;
            /*const connectionOptions = {
                transports: ['websocket', 'polling', 'flashsocket']
            }
            const socketClient = io(ENDPOINT, connectionOptions);*/
            const socketClient = io(ENDPOINT);
            setSocket(socketClient);

            console.log(socketClient.connected);

            socketClient.on('connect', function () {
                console.log("socket Connected!");
            });

            socketClient.on("connect_error", (err) => {
                console.log("connect failed" + err);
                console.log(`connect_error due to ${err.message}`);
            });

            socketClient.on("error", () => {
                console.log("Sorry, there seems to be an issue with the connection!");
            });
        }

        return () => {
            socket && socket.disconnect();
            setSocket(null);
            console.log("socket disconnected!");
        };
    }, []);

    return (
        <SocketContext.Provider value={{socket}}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketProvider;
