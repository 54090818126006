import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.AUTH_SERVER_PREFIX}/user-filter`
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getByWorkstationId: () => `${RESOURCE_ENDPOINT}/getByWorkstationId`,
    getByOrganogramId: () => `${RESOURCE_ENDPOINT}/getByOrganogramId`,
}

export default class UserApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public getByWorkstationId = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWorkstationId();
        return HttpService.get(url, params, headers);
      }

      public getByOrganogramId = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByOrganogramId();
        return HttpService.get(url, params, headers);
      }

}
