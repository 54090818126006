import React from 'react';
import './loader.scss';

const LazyLoader = () => {
    return (
        <div className="loader-bg">
            <div className="loader-track">
                <div className="loader-fill"/>
            </div>
        </div>
    );
};

export default LazyLoader;