import {useIFrameWindow} from "./useIFrameWindow";

interface IMessage {
    type: string
    [key: string]: any,
}

export const useWindowPostMessage = () => {
    const {iframeWindow} = useIFrameWindow();

    const postMessage = (iframeWindow, message: IMessage, origin: string = '*') => {
        if (iframeWindow) {
            iframeWindow.postMessage(message, origin);
        }
    }

    return {iframeWindow, postMessage}
}