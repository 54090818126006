import { useEffect } from 'react';
import { HttpService } from "@/services/http.services";
import _StorageService from "../services/storage.service";
import {OauthApi} from "@/api";

const useRefreshToken = (isAuthenticated:any) => {
  const StorageService = new _StorageService();

  // force refresh the token every 10 minutes
  const expireTime = 10 * 60 * 1000;

  useEffect(() => {
    let interval;

    if (isAuthenticated) {
      interval = setInterval(() => {
        refreshToken();
      }, expireTime);
    }

    return () => {
      if (interval) clearTimeout(interval)
    };
  }, [isAuthenticated]);


  const refreshToken = () => {
    const accessToken = StorageService.getAccessToken();
    const refreshToken = StorageService.getRefreshToken();
    if (!refreshToken) {
      return;
    }

    const payload = {
      refresh_token: refreshToken,
    }
    const params = {}
    const header = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    }

    OauthApi.refreshToken(payload, params, header)
      .then(res => {
        HttpService.setAccessToken(res.data.data.access_token);
        StorageService.setAccessToken(res.data.data.access_token);
        StorageService.setRefreshToken(res.data.data.refresh_token);
      })
      .catch(err => {
        // console.log(err);
      });
  };

  return refreshToken;
};

export default useRefreshToken;