export default class AuthService {
   /* static userHasPermission(user: User, permission: Permissions) {
        for (const perm of user.permissions) {
            if (perm.identifier === permission && perm.granted) {
                return true;
            }
        }

        return false;
    }*/
}