import React, {useEffect, useContext} from "react"
import {SocketContext} from "./SocketContext";
import {useLocation} from "../../hooks/dependentLists/useLocation";

interface IProps {
    children: any,
}

const SocketEventHandler: React.FC<IProps> = ({children}) => {
    const { socket } = useContext(SocketContext);
    const { loadLocationList } = useLocation();

    useEffect(() => {
        if (socket) {
            socket.on('core:location:updated', loadLocationList);
        }
    }, [socket]);

    return <>{children}</>;
}

export default SocketEventHandler
