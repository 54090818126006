import React, {createContext, useState, useEffect} from 'react'
import {OptionApi} from "../../api";

const initialState = {
    isLoading: false,
    isLoaded: false,
    fields: {
        // General Setting
        site_name: null,
        contact_address: null,
        copyright: null,

        // Email Setting
        admin_sender_name: null,
        admin_sender_email: null,

        // Notification Setting
        send_email_notification: null,
        send_sms_notification: null,
        send_push_notification: null,

        // Help Page
        support_phone: null,
        support_email: null,
        support_non_voice_cell_number: null,
    }
};

export const SettingContext = createContext<any>(initialState);

export const SettingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading);
    const [isLoaded, setIsLoaded] = useState<boolean>(initialState.isLoaded);
    const [fields, setFields] = useState<any>(initialState.fields);

    useEffect(() => {
        loadSetting();
    }, []);

    const loadSetting = async () => {
        setIsLoading(true);
        OptionApi.load()
            .then(res => {
                setFields(res.data.data);
            })
            .finally(() => {
                setIsLoaded(true);
                setIsLoading(false);
            });
    }

    const updateSettings = (values) => {
        setFields((prevState) => ({
          ...prevState,
          ...values
        }));
    };

    const contextValue = {
        isLoading, isLoaded, fields, setFields, updateSettings
    };

    return (
        <SettingContext.Provider value={contextValue}>
            {children}
        </SettingContext.Provider>
    );
};

export default SettingProvider;
