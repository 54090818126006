import {useContext, useEffect} from 'react'
import {useWindowPostMessage} from "../window/useWindowPostMessage";
import {CLEAR_AUTH_STATE, LOADED_TOKEN, REMOVE_TOKEN} from "../../context/auth/auth.types";
import {AuthContext} from "../../context/auth/auth.context";
import {SECURITY_UI_URL} from "../../constants/config.constant";

export const useAuthContext = () => {
    const {iframeWindow, postMessage} = useWindowPostMessage();
    const { isAuthenticated, dispatchAuth, loadAuthState} = useContext(AuthContext);

    useEffect(() => {
        window.addEventListener('message', handleWindowMessageListener);
        return () => {
            window.removeEventListener('message', handleWindowMessageListener);
        }
    }, []);

    useEffect(() => {
        if (iframeWindow) {
            sessionStorage.setItem('isAuthReady', 'No');
            postMessage(iframeWindow,{type: 'cross:domain:login:request'});
        }

    }, [iframeWindow]);

    const handleWindowMessageListener = (event, callback?: Function) => {
        if (event.data.type === 'cross:domain:user:login') {
            doCrossDomainUserLogin(event);
        }
        if (event.data.type === 'cross:domain:user:logout') {
            doCrossDomainUserLogout(event);
        }
    }

    const doCrossDomainUserLogin = (event) => {
        if (!isAuthenticated) {
            dispatchAuth({
                type: LOADED_TOKEN,
                payload: {
                    accessToken: event.data.accessToken,
                    refreshToken: event.data.refreshToken,
                }
            });
            loadAuthState(event.data.accessToken);
            sessionStorage.setItem('isAuthReady', 'Yes');
        }
        else {
            sessionStorage.setItem('isAuthReady', 'Yes');
        }
    }

    const doCrossDomainUserLogout = (event) => {
        if (event.data.response_type === 'send_message') {
            // @ts-ignore
            window.location = `${SECURITY_UI_URL}`;
        }
        else if (isAuthenticated === true || isAuthenticated === null) {
            dispatchAuth({type: REMOVE_TOKEN});
            dispatchAuth({type: CLEAR_AUTH_STATE});
            sessionStorage.setItem('isAuthReady', 'Yes');
        }
    }

}