import { useState, useEffect, useContext } from 'react'
import {ListContext} from "../../context/list/list.context";
//import {LocationTypeEnum} from "../../utils/enums";
import {LocationApi} from "../../api";

export const useLocation = (isPreloaded: boolean = true) => {
    const { locationListContext, setLocationListContext } = useContext(ListContext);

    const [locationList, setLocationList] = useState<any>([]);
    const [loadingLocationList, setLoadingLocationList] = useState<boolean>(false);

    useEffect(() => {
        if (isPreloaded && locationListContext.length) {
            setLocationList(locationListContext);
        }
        else {
            loadLocationList();
        }
    }, []);

    const loadLocationList = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoadingLocationList(true);
            const payload = {
                '$select': 'id,name_en,name_bn,parent_location_id,location_type_id,country_id,division_id,district_id,status',
                // '$filter': "location_type_id IN ('" + LocationTypeEnum.COUNTRY + "', '"+ LocationTypeEnum.DIVISION + "', '" + LocationTypeEnum.DISTRICT + "', '" + LocationTypeEnum.THANA + "')",
                '$orderby': 'name_en asc',
            }
            LocationApi.dropdown(payload)
                .then(res => {
                    prepareListData(res.data.data.results);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
                .finally(() => {
                    setLoadingLocationList(false);
                });
        });
    }

    const prepareListData = (list: any) => {
        setLocationList(list);
        if (isPreloaded) {
            setLocationListContext(list);
        }
    }

    return { loadingLocationList, loadLocationList, locationList }
}
