import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.UTIL_SERVER_PREFIX}/websocket`

const endpoints = {
    onEmit: () => `${RESOURCE_ENDPOINT}/emit`,
}

export default class WebsocketApi {
    public onEmit = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.onEmit();
        return HttpService.post(url, payload, params, headers);
    }
}