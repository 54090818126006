import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.TEST_CONSULTANCY_SERVER_PREFIX}/test`;
const endpoints = {
	list: () => `${RESOURCE_ENDPOINT}`,
	getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
	getTestInformationById: (id: any) => `${RESOURCE_ENDPOINT}/getTestInformationById/${id}`,
	getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
	create: () => `${RESOURCE_ENDPOINT}`,
	update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
	updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
	delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
	bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
	dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
	getTemplateInfoByTestId: (testId: any) => `${RESOURCE_ENDPOINT}/getTemplate/${testId}`,
	getTestWiseParameter: (testId: any) => `${RESOURCE_ENDPOINT}/getParameter/${testId}`,
	getTestListByOrganizationAndOrganogramWise: (organizationId: any, organogramId: any) => `${RESOURCE_ENDPOINT}/getTestListByOrganizationAndOrganogramWise/${organizationId}/${organogramId}`,
	getTestListWithoutPackage: (organizationId: any) => `${RESOURCE_ENDPOINT}/getTestListWithoutPackage/${organizationId}`,
}

export default class TestApi {
	public list = (params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.list();
		return HttpService.get(url, params, headers);
	}

	public getById = (id: any): AxiosPromise<any> => {
		const url = endpoints.getById(id);
		return HttpService.get(url);
	}

	public getTestInformationById = (id: any): AxiosPromise<any> => {
		const url = endpoints.getTestInformationById(id);
		return HttpService.get(url);
	}

	public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.getByWhere();
		return HttpService.get(url, params, headers);
	}

	public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.create();
		return HttpService.post(url, payload, params, headers);
	}

	public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.update(id);
		return HttpService.put(url, payload, params, headers);
	}

	public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.updatePartial(id);
		return HttpService.patch(url, payload, params, headers);
	}

	public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.delete(id);
		return HttpService.delete(url, params, headers);
	}

	public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.bulk();
		return HttpService.post(url, payload, params, headers);
	}

	public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
		const url = endpoints.dropdown();
		return HttpService.get(url, params, headers);
	}

	public getTemplateInfoByTestId = (testId: any): AxiosPromise<any> => {
		const url = endpoints.getTemplateInfoByTestId(testId);
		return HttpService.get(url);
	}

	public getTestWiseParameter = (testId: any): AxiosPromise<any> => {
		const url = endpoints.getTestWiseParameter(testId);
		return HttpService.get(url);
	}

	public getTestListByOrganizationAndOrganogramWise = (organizationId: any, organogramId: any = null): AxiosPromise<any> => {
		const url = endpoints.getTestListByOrganizationAndOrganogramWise(organizationId, organogramId);
		return HttpService.get(url);
	}

    public getTestListWithoutPackage = (organizationId: any): AxiosPromise<any> => {
		const url = endpoints.getTestListWithoutPackage(organizationId);
		return HttpService.get(url);
	}

}
