import _AuthService from "./auth.service";
/*import _HttpService from "./http.services";*/
import _CookieService from "./cookie.service";
import _CsvExportService from "./csv-export.service";
import _DownloadService from "./download.service";
import _EmailService from "./email.service";
import _FileService from "./file.service";
import _FilterService from "./filter.service";
import _LanguageService from "./language.service";
import _StorageService from "./storage.service";
import _MessageService from "./message.service";
import _NotificationService from "./notification.service";
import _PaginationService from "./pagination.service";
import _PdfExportService from "./pdf-export.service";
import _SmsService from "./sms.service";
import _UploadService from "./upload.service";
import _ValidationService from "./validation.service";
import _WindowService from "./window.service";
import _LoggerService from "./logger.service";
import _JwtService from "./jwt.service";
import _PermissionService from "./permission.service";

export const WindowService              = new _WindowService();
export const CookieService              = new _CookieService();
export const LoggerService              = new _LoggerService();
export const StorageService             = new _StorageService();
export const PermissionService          = new _PermissionService();
export const AuthService                = new _AuthService();
export const CsvExportService           = new _CsvExportService();
export const DownloadService            = new _DownloadService();
export const EmailService               = new _EmailService();
export const FileService                = new _FileService();
export const FilterService              = new _FilterService();
export const LanguageService            = new _LanguageService();
export const MessageService             = new _MessageService();
export const NotificationService        = new _NotificationService();
export const PaginationService          = new _PaginationService();
export const PdfExportService           = new _PdfExportService();
export const SmsService                 = new _SmsService();
export const UploadService              = new _UploadService();
export const ValidationService          = new _ValidationService();
export const JwtService                 = new _JwtService();
/*export const HttpService            = new _HttpService();*/
