import {useEffect, useState} from "react";
import {SECURITY_UI_URL} from "../../constants/config.constant";

export function useIFrameWindow() {
    const [iframeWindow, setIframeWindow] = useState<any>(null);

    useEffect(() => {
        if(!document.getElementById("post-message-iframe")) {
            const iframe = document.createElement('iframe');
            iframe.src = `${SECURITY_UI_URL}/post_message_to_frame_owner.html`;
            iframe.setAttribute('id', 'post-message-iframe');
            iframe.style.display = 'none';
            iframe.onload = () => {
                if (iframe.contentWindow) {
                    setIframeWindow(iframe.contentWindow);
                }
            };

            document.body.appendChild(iframe);
        }
        else {
            const iframe = document.getElementById('post-message-iframe');
            // @ts-ignore
            setIframeWindow(iframe.contentWindow);
        }
    }, []);

    return {iframeWindow}
}
