import {LOG_ENABLED} from "../constants/config.constant";

export default class LoggerService {
  public log = (...args: any[]) => {
    if (LOG_ENABLED) {
      console.log(...args);
    }
  }

  public error = (...args: any[]) => {
    if (LOG_ENABLED) {
      console.error(...args);
    }
  }
}

